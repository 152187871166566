import { Schema } from "@griddo/core";

export const PROGRAM_TYPE: Schema.SimpleContentType = {
	dataPacks: ["PROGRAMS_PACK", "EVENTS_PACK"],
	title: "Program Type",
	local: true,
	translate: true,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				type: "TextField",
				mandatory: true,
			},
		],
	},
};
