import { Schema } from "@griddo/core";

export const PROGRAM: Schema.PageContentType = {
	dataPacks: ["PROGRAMS_PACK", "EVENTS_PACK"],
	title: "Program",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["ProgramDetail"],
		fields: [
			// Title is mandatory and must be in the first place of `fields`
			{
				type: "TextField",
				key: "title",
				title: "Title",
				from: "title",
			},

			{
				type: "RichText",
				title: "Study intro",
				key: "intro",
				from: "intro",
			},

			{
				type: "AsyncCheckGroup",
				title: "Areas",
				key: "areas",
				from: "areas",
				source: "AREAS",
			},

			{
				type: "AsyncCheckGroup",
				title: "ProgramType",
				key: "programType",
				from: "programType",
				source: "PROGRAM_TYPE",
			},

			{
				type: "CheckGroup",
				title: "Degree type",
				key: "degree_type",
				from: "degree_type",
				options: [
					{ title: "Official", name: "official" },
					{ title: "Custom", name: "custom" },
				],
			},

			{
				type: "TextField",
				title: "Learning Option",
				key: "learningOption",
				from: "learningOption",
			},

			{
				type: "AsyncCheckGroup",
				title: "Language",
				key: "language",
				from: "language",
				source: "LANGUAGE",
			},

			{
				type: "TextField",
				title: "Credits",
				key: "credits",
				from: "credits",
			},

			{
				type: "TextField",
				title: "Places",
				key: "places",
				from: "places",
			},

			{
				type: "UniqueCheck",
				title: "New",
				options: [{ title: "new" }],
				key: "isNew",
				from: "isNew",
			},

			{
				type: "ComponentArray",
				title: "Hero Content",
				key: "heroSection",
				from: "heroSection",
				contentType: "modules",
				whiteList: ["HeroProgram"],
				maxItems: 1,
			},

			{
				type: "ComponentArray",
				key: "navigationContent",
				from: "navigationContent.modules",
				contentType: "modules",
				title: "Navigation Content",
				whiteList: ["ProgramNavigation"],
			},

			{
				type: "TextField",
				title: "CRM id",
				key: "crmId",
				from: "crmId",
			},

			{
				type: "ImageField",
				title: "Program Image",
				key: "programImage",
				from: "programImage",
			},

			{
				type: "TextField",
				title: "Programme",
				key: "programTypeLabel",
				from: "programTypeLabel",
			},
		],
	},
};
